import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';
import tw, { styled } from 'twin.macro';

import PageContainer from '../components/page-container';
import SEO from '../components/seo';
import {
  TitleBar,
  PageTitle,
  SectionTitle,
  PageSection,
  FlexContainer,
  TextContainer,
} from '../utils/elements.stc';

export const LodgingSummaryMap = styled.div`
  ${tw`w-full rounded bg-primary-100 mb-8`}
  max-width: 450px;
  max-height: 300px;
`;
export const LodgingImageContainer = styled.div`
  ${tw`w-full bg-primary-100 mb-4`}
  max-width: 450px;
`;
export const PropertyLink = styled.a`
  ${tw`block pt-4 text-center underline uppercase font-bold text-base`}
`;

const BasaltLodgingPage = ({ data }) => {
  const {
    sanityBasaltLodgingPage: {
      seo: { title, description, keywords },
      _rawBody,
      _rawAspenaltText,
      _rawFprlText,
      aspenaltImage: {
        alt: aspenaltImageAlt,
        asset: { fluid: aspenaltImageFluid },
      },
      fprlImage: {
        alt: fprlImageAlt,
        asset: { fluid: fprlImageFluid },
      },
    },
    staticMap: {
      childFile: {
        childImageSharp: { fluid: staticMapFluid },
      },
    },
    sanityAffiliations: { affiliates },
  } = data;

  const aspenaltData = affiliates.find(item =>
    item.affiliateUrl.match(/aspenalt/)
  );
  const fprlData = affiliates.find(item =>
    item.affiliateUrl.match(/riverlodge/)
  );

  return (
    <PageContainer>
      <SEO title={title} description={description} keywords={keywords} />
      <TitleBar>
        <PageTitle tw="font-light md:font-light">{title}</PageTitle>
      </TitleBar>
      <PageSection>
        <FlexContainer>
          <TextContainer tw="md:w-1/3 leading-7">
            {_rawBody && <BlockContent blocks={_rawBody} />}
          </TextContainer>
          <LodgingSummaryMap>
            <Img
              fluid={staticMapFluid}
              alt="Static map of area showing cabin location as red pin."
              tw="rounded border-2 border-gray-100 shadow-md"
            />
          </LodgingSummaryMap>
        </FlexContainer>
      </PageSection>
      <PageSection bglight>
        <SectionTitle normal>Aspenalt</SectionTitle>
        <FlexContainer reverse tw="mb-4 border-b-2 border-gray-100">
          <TextContainer>
            {_rawAspenaltText && <BlockContent blocks={_rawAspenaltText} />}
            <PropertyLink href={aspenaltData.affiliateUrl}>
              Visit Aspenalt
            </PropertyLink>
          </TextContainer>
          <LodgingImageContainer>
            <Img
              fluid={aspenaltImageFluid}
              alt={aspenaltImageAlt}
              tw="rounded border-2 border-white"
            />
          </LodgingImageContainer>
        </FlexContainer>
        <SectionTitle normal>Frying Pan River Lodge</SectionTitle>
        <FlexContainer reverse>
          <TextContainer>
            {_rawFprlText && <BlockContent blocks={_rawFprlText} />}
            <PropertyLink href={fprlData.affiliateUrl}>
              Visit Frying Pan River Lodge
            </PropertyLink>
          </TextContainer>
          <LodgingImageContainer>
            <Img
              fluid={fprlImageFluid}
              alt={fprlImageAlt}
              tw="rounded border-2 border-white"
            />
          </LodgingImageContainer>
        </FlexContainer>
      </PageSection>
    </PageContainer>
  );
};

export default BasaltLodgingPage;

export const query = graphql`
  query BasaltLodgingPageQuery {
    sanityBasaltLodgingPage {
      seo {
        title
        keywords
        description
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawAspenaltText(resolveReferences: { maxDepth: 10 })
      _rawFprlText(resolveReferences: { maxDepth: 10 })
      aspenaltImage {
        alt
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      fprlImage {
        alt
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    staticMap(nickname: { eq: "basaltLodgingPage" }) {
      childFile {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    sanityAffiliations {
      affiliates {
        affiliateName
        affiliateUrl
      }
    }
  }
`;
